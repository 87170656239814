(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ticket-list-item.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ticket-list-item.js');
"use strict";


const {
  useHistory
} = ReactRouterDOM;
const {
  useAtomValue,
  Provider
} = jotai;
const Dots = svs.ui.ReactDots;
const Icon = svs.ui.ReactIcon;
const Button = svs.ui.ReactButton;
const {
  onBuyMoreClick
} = svs.supportern.utils.tracking;
const {
  Card
} = svs.ui.ReactCard;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  ListGroup,
  ListItem
} = svs.ui.ReactListGroup;
const {
  CATEGORY
} = svs.supportern.utils.translation;
const {
  ExpandableBox,
  Header: BoxHeader,
  Body: BoxBody
} = svs.components.expandableBox;
const {
  Avatar,
  Ticket,
  Countdown,
  RaffleInformation,
  Receipt,
  LowTicketsDisclaimer
} = svs.supportern.components;
const {
  ticketAtom,
  teamAtom,
  drawAtom
} = svs.supportern.atoms;
const GREY = '#E1E1E5';
const MINIMUM_SOLD_TICKETS = 6;
const TICKETS_BEFORE_STUB = 3;
const TicketListItem = _ref => {
  var _results$stats$poolSi, _prize$amount, _prize$amount2;
  let {
    onClick,
    isOngoing
  } = _ref;
  const history = useHistory();
  const ticket = useAtomValue(ticketAtom);
  const {
    draw,
    ticketsInfo,
    buy,
    resultsPresented,
    id,
    prize
  } = ticket;
  const {
    drawingTime,
    status: drawStatus,
    results
  } = draw;
  const settledTime = dateFns.format(new Date(drawingTime), "d/M', kl. 'HH:mm");
  const ticketNumbersRanges = ticketsInfo.map(_ref2 => {
    let {
      numbersRange
    } = _ref2;
    return numbersRange;
  });
  const numberOfTickets = ticketNumbersRanges.reduce((acc, _ref3) => {
    let {
      from,
      to
    } = _ref3;
    return acc + (to - from) + 1;
  }, 0);
  const ticketsBought = buy.price.amount / draw.price.amount;
  const useStubs = numberOfTickets > TICKETS_BEFORE_STUB;
  const totalTickets = results.stats.totalRows;
  const poolSize = (_results$stats$poolSi = results.stats.poolSize) === null || _results$stats$poolSi === void 0 ? void 0 : _results$stats$poolSi.amount;
  const hasEnoughTickets = totalTickets >= MINIMUM_SOLD_TICKETS;
  const isActive = drawStatus === 'ACTIVE';
  const isCancelled = drawStatus === 'CANCELLED';
  const isExamined = Boolean(resultsPresented === null || resultsPresented === void 0 ? void 0 : resultsPresented.length);
  const ongoingTicketLink = svs.supportern.routes.ONGOING_DETAIL_PATH.replace(':id', id);
  const drawTicketLink = svs.supportern.routes.DETAIL_PATH.replace(':id', id);
  const hasWin = Boolean(prize === null || prize === void 0 || (_prize$amount = prize.amount) === null || _prize$amount === void 0 ? void 0 : _prize$amount.amount);
  const correctedText = hasWin ? "".concat(prize === null || prize === void 0 || (_prize$amount2 = prize.amount) === null || _prize$amount2 === void 0 ? void 0 : _prize$amount2.amount, " kr") : 'Ingen vinst';
  const {
    TEAM_PATH
  } = svs.supportern.routes;
  const {
    ownerTeam: {
      id: teamId
    }
  } = draw;
  const handleBuyMoreClick = e => {
    e.preventDefault();
    onBuyMoreClick();
    svs.utils.url.navigate("".concat(svs.core.data.baseUrl).concat(TEAM_PATH.replace(':id', teamId)));
  };
  return React.createElement(Card, {
    className: "".concat(useStubs ? 'stub-card' : '', " margin-top-2")
  }, React.createElement(ListGroup, null, React.createElement(ListItem, {
    className: "padding-0"
  }, React.createElement("div", {
    id: "ticketId_".concat(id),
    onClick: onClick
  }, React.createElement(ExpandableBox, null, isExpanded => {
    var _draw$ownerTeam$categ;
    return React.createElement(Provider, {
      initialValues: [[teamAtom, draw.ownerTeam], [drawAtom, draw], [ticketAtom, ticket]]
    }, React.createElement(BoxHeader, {
      className: "bg-tur-100",
      id: "test"
    }, React.createElement("div", {
      className: "padding-1"
    }, React.createElement("i", {
      className: "icon-default ".concat(isExpanded ? 'icon-menu-up' : 'icon-menu-down', " expandable-box-icon right")
    }), React.createElement("div", {
      className: "u-flex"
    }, React.createElement(Avatar, {
      size: "small"
    }), React.createElement("div", {
      className: "padding-left-1"
    }, React.createElement("div", null, draw.ownerTeam.sport.name), React.createElement("div", {
      className: "f-550"
    }, draw.ownerTeam.name), React.createElement("div", null, CATEGORY[(_draw$ownerTeam$categ = draw.ownerTeam.category) === null || _draw$ownerTeam$categ === void 0 ? void 0 : _draw$ownerTeam$categ.name.toUpperCase()] || draw.ownerTeam.category.name), React.createElement("div", null, React.createElement("span", {
      className: "f-550 verticle-align-top"
    }, ticketsBought, " ", draw.type === 'MATCH' ? 'matchspel' : 'eventspel - '), draw.type === 'EVENT' && React.createElement("span", {
      className: "overflow-ellipsis-description verticle-align-top"
    }, draw.drawName))))), React.createElement(Dots, {
      branding: "supportern",
      className: "padding-1 ".concat(isExpanded ? 'padding-bottom-0' : '')
    }, isActive && React.createElement(Countdown, null), !isActive && React.createElement("div", {
      className: "u-flex u-flex-justify-content-between"
    }, React.createElement("span", null, "Avgjordes: ".concat(isCancelled ? 'Inställd' : settledTime)), !isOngoing && !isCancelled && React.createElement("span", {
      className: !isExamined || hasWin ? 'f-bold' : ''
    }, isExamined ? correctedText : 'Orättat', isExamined && React.createElement("span", {
      className: "inline-block finished-bet ".concat(hasWin ? 'bg-green' : 'bg-red')
    }))))), React.createElement(BoxBody, {
      className: "padding-1 padding-top-0"
    }, React.createElement(GridRow, {
      className: "padding-top-3"
    }, ticketNumbersRanges.map(_ref4 => {
      let {
        from,
        to
      } = _ref4;
      return _.range(from, to + 1).map(ticketNr => React.createElement(GridCol, {
        className: "u-flex u-flex-justify-content-center",
        key: "".concat(ticketNr, "-ticket-something-a-key"),
        md: 2,
        sm: 4,
        style: {
          marginTop: 0
        },
        xs: useStubs ? 4 : 6
      }, React.createElement(Ticket, {
        className: "filter-shadow-100",
        isCancelled: isCancelled,
        isStub: useStubs,
        ticketNr: ticketNr
      })));
    }), isActive && !useStubs && React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center",
      md: 2,
      sm: 4,
      style: {
        marginTop: 0
      },
      xs: 6
    }, React.createElement(Ticket, {
      branding: {
        color: GREY,
        colorBottom: GREY
      },
      className: "filter-shadow-100"
    }, React.createElement("div", {
      className: "w100",
      style: {
        backgroundColor: GREY,
        height: '184px'
      }
    }, React.createElement("a", {
      className: "f-550 fc-black h100 u-flex u-flex-justify-content-center u-flex-align-items-center u-flex-wrap u-flex-column",
      href: "#",
      onClick: handleBuyMoreClick
    }, React.createElement(Icon, {
      className: "margin-bottom-1",
      icon: "plus"
    }), "K\xF6p fler spel"))))), useStubs && React.createElement(React.Fragment, null, !isCancelled && React.createElement(GridRow, {
      className: "padding-top-2"
    }, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center",
      md: {
        col: 4,
        offset: 4
      },
      sm: {
        col: 6,
        offset: 3
      },
      style: {
        marginTop: 0
      },
      xs: 12
    }, React.createElement("div", null, React.createElement("span", null, " ", isActive ? 'Vinstsumma just nu' : 'Vinstchans'), React.createElement("div", {
      className: "f-800 padding-top-half align-center"
    }, React.createElement("span", null, hasEnoughTickets ? svs.utils.format.Currency(poolSize) : 0), React.createElement("span", {
      className: "f-400"
    }, "\xA0kr"))))), isActive && React.createElement(GridRow, {
      className: "padding-top-3"
    }, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center",
      md: {
        col: 4,
        offset: 4
      },
      sm: {
        col: 6,
        offset: 3
      },
      style: {
        marginTop: 0
      },
      xs: 12
    }, React.createElement(Button, {
      block: true,
      className: "margin-bottom-1 margin-top-0",
      inverted: true,
      onClick: handleBuyMoreClick,
      size: 300
    }, "K\xF6p fler spel")))), !poolSize && React.createElement(LowTicketsDisclaimer, null), !isCancelled && React.createElement("div", {
      className: "margin-top-2"
    }, React.createElement(RaffleInformation, {
      isSettled: !isActive
    })), isActive && React.createElement(Receipt, null)));
  }))), (svs.core.detect.feature('ft-supportern-mygaming-redesign') || !isOngoing && !isActive && !isCancelled) && React.createElement(ListItem, {
    className: "align-center"
  }, React.createElement("a", {
    className: "f-550 fc-black f-uppercase",
    href: isOngoing ? ongoingTicketLink : drawTicketLink,
    onClick: e => {
      e.preventDefault();
      history.push(isOngoing ? ongoingTicketLink : drawTicketLink);
    }
  }, isOngoing || isExamined ? 'Visa spel' : 'Visa dragning'))));
};
setGlobal('svs.supportern.components.composites.TicketListItem', TicketListItem);

 })(window);