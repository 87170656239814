(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/play-router.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/play-router.js');
"use strict";



const {
  PlayerRouter,
  Route
} = svs.app.ReactRouter;
const {
  baseUrl
} = svs.core.data;
const {
  ALL_TEAMS_PATH,
  FAVORITES_PATH,
  UPCOMING_PATH,
  POPULAR_PATH,
  TEAM_PATH,
  START_PATH
} = svs.supportern.routes;
const {
  AllTeams,
  Favorites,
  Popular,
  Upcoming,
  Team,
  Start
} = svs.supportern.routes;
const {
  BallMenu,
  Loader
} = svs.supportern.components;
const {
  ScrollToTop,
  PageTitle,
  AnchorBehaviour
} = svs.supportern.utils;
const {
  Provider
} = jotai;
const queryClient = new queryCore.QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false
    }
  }
});
jotaiTanstackQuery.queryClientAtom = jotai.atom(queryClient);
jotaiTanstackQuery.queryClientAtom.isSvSHack = true;
const {
  drawsFilterAtom,
  teamIdAtom,
  teamsFilterAtom
} = svs.supportern.atoms;
const {
  Suspense
} = React;
setGlobal('svs.supportern.isPlayRoute', true);
const menuItems = [{
  label: 'Start',
  icon: 'nav-home',
  path: START_PATH
}, {
  label: 'Favorit',
  icon: 'favorites',
  path: FAVORITES_PATH
}, {
  label: 'Kommande',
  icon: 'tips',
  path: UPCOMING_PATH
}, {
  label: 'Populära',
  icon: 'hot',
  path: POPULAR_PATH
}, {
  label: 'Alla lag',
  icon: 'team',
  path: ALL_TEAMS_PATH
}];
const PlayRouter = () => React.createElement(Provider, null, React.createElement(Suspense, {
  fallback: React.createElement(Loader, null)
}, React.createElement(PlayerRouter, {
  basename: baseUrl,
  withRouter: true
}, React.createElement(PageTitle, null), React.createElement(AnchorBehaviour, {
  block: window.innerHeight < 800 ? 'end' : 'center'
}), React.createElement(ScrollToTop, null), React.createElement(BallMenu, {
  menuItems: menuItems
}), React.createElement(Route, {
  component: Start,
  exact: true,
  path: START_PATH
}), React.createElement("div", {
  className: "supportern-main"
}, React.createElement(Route, {
  component: () => React.createElement(Provider, {
    initialValues: [[teamsFilterAtom, 'favorites=true']]
  }, ' ', React.createElement(Favorites, null)),
  exact: true,
  path: FAVORITES_PATH
}), React.createElement(Route, {
  component: Popular,
  exact: true,
  path: POPULAR_PATH
}), React.createElement(Route, {
  component: Upcoming,
  exact: true,
  path: UPCOMING_PATH
}), React.createElement(Route, {
  component: AllTeams,
  exact: true,
  path: ALL_TEAMS_PATH
}), React.createElement(Route, {
  component: _ref => {
    let {
      match
    } = _ref;
    const {
      id
    } = match.params;
    return React.createElement(Provider, {
      initialValues: [[teamIdAtom, id], [drawsFilterAtom, "teamId=".concat(id, "&sortBy=salesStart&status=ACTIVE&status=PENDING")]],
      key: id
    }, React.createElement(PageTitle, null), React.createElement(Team, null));
  },
  exact: true,
  path: TEAM_PATH
})))));
if (!svs.supportern.logger) {
  setGlobal('svs.supportern.logger', svs.core.log.getLogger('supportern:play'));
}
const container = document.getElementById('reactContainer');
container && ReactDOM.render(React.createElement(PlayRouter, null), container);

 })(window);