(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/my-tickets/ongoing-detail.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/my-tickets/ongoing-detail.js');
"use strict";


const {
  useAtom,
  Provider
} = jotai;
const Button = svs.ui.ReactButton;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  TEAM_PATH
} = svs.supportern.routes;
const {
  teamAtom,
  drawAtom,
  ticketAtom,
  ticketByIdAtom,
  ticketIdAtom
} = svs.supportern.atoms;
const {
  RaffleInformation,
  TicketsSummary
} = svs.supportern.components;
const {
  getIcon,
  standardCardLayout
} = svs.supportern.utils.tools;
setGlobal('svs.supportern.routes', {
  ONGOING_DETAIL_PATH: '/pagaende/:id',
  ONGOING_DETAIL_PATH_GUID: '/pagaende',
  OngoingDetail: () => {
    const [ticket] = useAtom(ticketByIdAtom);
    const {
      draw,
      ticketsInfo,
      id
    } = ticket;
    const ticketNumbersRanges = ticketsInfo.map(_ref => {
      let {
        numbersRange
      } = _ref;
      return numbersRange;
    });
    const {
      ownerTeam
    } = draw;
    const {
      background
    } = ownerTeam.association.branding;
    const handleBuyBtnClick = () => {
      window.location = "".concat(svs.core.data.baseUrl, "/").concat(TEAM_PATH.replace(':id', ownerTeam.id));
    };
    return React.createElement(Provider, {
      initialValues: [[drawAtom, draw], [teamAtom, ownerTeam], [ticketAtom, ticket], [ticketIdAtom, id]]
    }, React.createElement(Grid, null, React.createElement("div", null, React.createElement("div", {
      className: "f-center relative opacity-60-navy hero-container"
    }, React.createElement("img", {
      className: "team-hero-image",
      src: getIcon(background)
    }), React.createElement("div", {
      className: "supportern-summary relative"
    }, React.createElement("h1", {
      className: "f-center f-900 fc-white"
    }, "Mina Spel"), React.createElement(TicketsSummary, {
      ticketNrs: ticketNumbersRanges
    }))), React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "relative"
    }, React.createElement("div", {
      className: "supportern-constrain 'supportern-detail'}"
    }, React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(GridCol, standardCardLayout, React.createElement(RaffleInformation, null))), React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(GridCol, standardCardLayout, React.createElement(Button, {
      block: true,
      className: "margin-top-1 margin-bottom-1",
      onClick: handleBuyBtnClick,
      transparent: true
    }, "Fler spel fr\xE5n ", ownerTeam.name)))))))));
  }
});

 })(window);