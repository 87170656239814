(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/my-tickets/ongoing.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/my-tickets/ongoing.js');
"use strict";

const {
  useEffect
} = React;
const {
  useHistory
} = ReactRouterDOM;
const {
  OngoingList
} = svs.supportern.components.composites;
const {
  useAtom,
  Provider
} = jotai;
const {
  activeTicketsAtom,
  ticketsAtom
} = svs.supportern.atoms;
const {
  STATUS
} = svs.supportern.utils.tools;
const {
  baseUrl
} = svs.core.data;
setGlobal('svs.supportern.routes', {
  ONGOING_PATH: '/',
  Ongoing: () => {
    const [tickets] = useAtom(activeTicketsAtom);
    const {
      FAVORITES_PATH,
      DETAIL_PATH,
      ONGOING_DETAIL_PATH
    } = svs.supportern.routes;
    const history = useHistory();
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const drawId = urlParams.get('spel');
      const ticket = tickets.find(ticket => ticket.draw.id === drawId);
      if (ticket) {
        const ticketPath = ticket.draw.status === STATUS.ACTIVE ? ONGOING_DETAIL_PATH.replace(':id', ticket.id) : DETAIL_PATH.replace(':id', ticket.id);
        history.push(ticketPath);
      }
    });
    return React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("h2", {
      className: "h2 margin-bottom-1 margin-top-3 padding-bottom f-medium"
    }, "P\xE5g\xE5ende spel"), tickets.length ? React.createElement(Provider, {
      initialValues: [[ticketsAtom, tickets]]
    }, React.createElement(OngoingList, null)) : React.createElement(React.Fragment, null, React.createElement("p", null, "Du har inga p\xE5g\xE5ende spel."), React.createElement("a", {
      href: "".concat(baseUrl, "/").concat(FAVORITES_PATH)
    }, "G\xE5 till dina favoritlag och k\xF6p spel")));
  }
});

 })(window);