(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/tools.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/tools.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  apiLocation
} = svs.supportern.data.config;
const tools = {
  STATUS: {
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
    PENDING: 'PENDING',
    PRIZE_CHECKED: 'PRIZE_CHECKED'
  },
  DRAW_TYPES: {
    MATCH: 'MATCH',
    EVENT: 'EVENT'
  },
  TRACKING_DRAW_TYPES: {
    MATCH: 'Matchlotteri',
    EVENT: 'Eventlotteri'
  },
  MY_TICKETS_PATH: '/mina-supporternspel',
  getIcon: icon => "".concat(apiLocation === null || apiLocation === void 0 ? void 0 : apiLocation.split('/api')[0]).concat(icon),
  standardCardLayout: {
    md: 4,
    am: 6,
    sm: 8,
    xs: 12
  },
  mergeTeamsWithDraws: (teams, draws) => teams.map(team => tools.mergeTeamWithDraws(team, draws)),
  mergeTeamWithDraws: function (team) {
    let draws = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return _objectSpread(_objectSpread({}, team), {}, {
      draws: draws.filter(draw => {
        var _draw$ownerTeam;
        return (draw === null || draw === void 0 || (_draw$ownerTeam = draw.ownerTeam) === null || _draw$ownerTeam === void 0 ? void 0 : _draw$ownerTeam.id) === team.id;
      })
    });
  },
  mergeCompetitionsWithTeams: (competitions, teams) => competitions.map(c => _objectSpread(_objectSpread({}, c), {}, {
    teams: teams.filter(team => {
      var _team$competitions;
      return (_team$competitions = team.competitions) === null || _team$competitions === void 0 ? void 0 : _team$competitions.find(_ref => {
        let {
          id
        } = _ref;
        return id === c.id;
      });
    })
  })),
  mergeCompetitionsWithDraws: (competitions, draws) => competitions.map(c => _objectSpread(_objectSpread({}, c), {}, {
    draws: draws.filter(draw => {
      var _draw$ownerTeam$compe;
      return (_draw$ownerTeam$compe = draw.ownerTeam.competitions) === null || _draw$ownerTeam$compe === void 0 ? void 0 : _draw$ownerTeam$compe.find(_ref2 => {
        let {
          id
        } = _ref2;
        return id === c.id;
      });
    })
  })),
  mergeCompetitionsWithPlayerDraws: (competitions, playerDraws) => competitions.map(competition => _objectSpread(_objectSpread({}, competition), {}, {
    playerDraws: playerDraws.filter(playerDraw => {
      var _playerDraw$draw$owne;
      return ((_playerDraw$draw$owne = playerDraw.draw.ownerTeam.competitions[0]) === null || _playerDraw$draw$owne === void 0 ? void 0 : _playerDraw$draw$owne.id) === competition.id;
    })
  })),
  setMetaTags: teamName => {
    const title = "Jag st\xF6ttar ".concat(teamName, "!");
    const description = 'Stöd DIN förening via spelet Supportern, Svenska Spel Tur.';
    const image = "".concat(svs.core.config.data.svsconfig.cdn, "/images/supportern/supportern-share.png");
    const tags = [
    {
      attribute: 'property',
      attributeValue: 'og:title',
      content: title
    }, {
      attribute: 'property',
      attributeValue: 'og:description',
      content: description
    }, {
      attribute: 'property',
      attributeValue: 'og:image',
      content: image
    },
    {
      attribute: 'name',
      attributeValue: 'twitter:title',
      content: title
    }, {
      attribute: 'name',
      attributeValue: 'twitter:description',
      content: description
    }, {
      attribute: 'name',
      attributeValue: 'twitter:image',
      content: image
    }];
    for (const {
      attribute,
      attributeValue,
      content
    } of tags) {
      const existingTag = document.querySelector("meta[".concat(attribute, "=\"").concat(attributeValue, "\"]"));
      const metaTag = existingTag ? existingTag : document.createElement('meta');
      metaTag.setAttribute('content', content);
      if (!existingTag) {
        metaTag.setAttribute(attribute, attributeValue);
        document.getElementsByTagName('head')[0].appendChild(metaTag);
      }
    }
  },
  moveAnchorToQuery: () => {
    const anchor = encodeURIComponent(window.location.hash);
    const url = window.location.origin + window.location.pathname + window.location.search;
    history.replaceState(null, null, url);
    if (anchor) {
      svs.utils.paramHandler.set({
        key: 'anchor',
        value: anchor
      });
    }
  }
};
setGlobal('svs.supportern.utils.tools', tools);
const ONE_MIN = 1000 * 60;
const ONE_HOUR = ONE_MIN * 60;
let drawId = 0;
const generateDraw = _ref3 => {
  let {
    now = new Date(),
    status = svs.supportern.utils.tools.STATUS.OPEN,
    ownerTeam = _.sample(teams),
    type = svs.supportern.utils.tools.DRAW_TYPES.MATCH
  } = _ref3;
  const pendingSalesStart = _.random(1000 * 60 * 2, 1000 * 60 * 60 * 48);
  const finalizedSalesStart = _.random(ONE_HOUR * 96, ONE_HOUR * 120);
  const salesEndRng = _.random(_.sample([_.random(0, ONE_HOUR * 23), _.random(ONE_HOUR * 24, ONE_HOUR * 48)]));
  const price = _.sample([10, 20, 30]);
  const totalPlayers = _.random(1, 1000);
  const totalTickets = totalPlayers * _.random(1, 15);
  return {
    id: "draw_".concat(drawId++),
    drawName: 'Some draw name',
    objective: 'Rasie funds for new season',
    status,
    get salesStart() {
      switch (status) {
        case svs.supportern.utils.tools.STATUS.OPEN:
          return now.toISOString();
        case svs.supportern.utils.tools.STATUS.PENDING:
          {
            const newTime = new Date();
            newTime.setTime(now.getTime() + pendingSalesStart);
            return newTime.toISOString();
          }
        case svs.supportern.utils.tools.STATUS.FINALIZED:
          {
            const newTime = new Date();
            newTime.setTime(now.getTime() - finalizedSalesStart);
            return newTime.toISOString();
          }
        default:
          return now.toISOString();
      }
    },
    get salesEnd() {
      const newTime = new Date();
      newTime.setTime(new Date(this.salesStart).getTime() + salesEndRng);
      return newTime.toISOString();
    },
    get drawingTime() {
      return this.salesEnd;
    },
    get msUntilDraw() {
      return new Date(this.salesEnd).getTime() - now.getTime();
    },
    price: {
      currency: 'EUR',
      amount: price
    },
    ownerTeam,
    opponentTeam: _.sample(teams.filter(t => t.id !== ownerTeam.id)),
    homeGame: true,
    type,
    results: {
      stats: {
        totalRows: totalTickets,
        totalPlayers,
        poolSize: {
          currency: 'EUR',
          amount: totalTickets * price / 2
        }
      }
    }
  };
};
setGlobal('svs.supportern.utils.tools.generateDraw', generateDraw);
let teams = [{
  id: 'refgwe45hgw45gq',
  name: 'Brynäs IF',
  shortName: 'Brynäs IF',
  code: 'BIF',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: '54whsrthw5ygq345',
    name: 'Brynäs IF',
    shortName: 'Brynäs IF',
    organisationNumber: '123658974856',
    branding: {
      logo: 'brynas.png',
      background: 'bg.png',
      color: '#ffd149'
    }
  }
}, {
  id: 'w54gsthsrth4tae5hga',
  name: 'Djurgården Hockey',
  shortName: 'Djurgården',
  code: 'DIF',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'w45gsrthae4tq45y',
    name: 'Djurgården IF',
    shortName: 'Djurgården IF',
    organisationNumber: '123658974856',
    branding: {
      logo: 'djurgarden.png',
      background: 'bg.png',
      color: '#2E64B6'
    }
  }
}, {
  id: 'djtyedtyjjs5y',
  name: 'Frölunda IF',
  shortName: 'Frölunda IF',
  code: 'FIF',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'w45hsrthndtyjsegjd',
    name: 'Frölunda IF',
    shortName: 'Frölunda IF',
    organisationNumber: '123658974856',
    branding: {
      logo: 'frolunda.png',
      background: 'bg.png',
      color: '#BF2F38'
    }
  }
}, {
  id: 'w564ujwsnsergs6jh',
  name: 'Linköping HF',
  shortName: 'Linköping HF',
  code: 'LHF',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'wh6shgrts54tw4hwh',
    name: 'Linköping HF',
    shortName: 'Linköping HF',
    organisationNumber: '123658974856',
    branding: {
      logo: 'linkoping.png',
      background: 'bg.png',
      color: '#255099'
    }
  }
}, {
  id: 'we456hsthsehgw4t',
  name: 'Leksand IF',
  shortName: 'Leksand IF',
  code: 'LIF',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'he65yujdhsrth',
    name: 'Leksand IF',
    shortName: 'Leksand IF',
    organisationNumber: '123658974856',
    branding: {
      logo: 'leksand.png',
      background: 'bg.png',
      color: '#003581'
    }
  }
}, {
  id: '65dthsahw6hjw',
  name: 'Färjestad BK',
  shortName: 'Färjestad BK',
  code: 'FBK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: '655hthseerga4a',
    name: 'Färjestad BK',
    shortName: 'Färjestad BK',
    organisationNumber: '123658974856',
    branding: {
      logo: 'karlstad.png',
      background: 'bg.png',
      color: '#009B5D'
    }
  }
}, {
  id: 'srtghbghmnzerth5',
  name: 'Luleå Hockey',
  shortName: 'Luleå Hockey',
  code: 'LHK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'je57j5ys54ytw6h',
    name: 'Luleå Hockey',
    shortName: 'Luleå Hockey',
    organisationNumber: '123658974856',
    branding: {
      logo: 'lulea.png',
      background: 'bg.png',
      color: '#565454'
    }
  }
}, {
  id: 'w56ejdtysy6',
  name: 'Malmö Redhawks',
  shortName: 'Malmö Redhawks',
  code: 'MR',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'dre6hje56y45y56w6',
    name: 'Malmö Redhawks',
    shortName: 'Malmö Redhawks',
    organisationNumber: '123658974856',
    branding: {
      logo: 'malmo.png',
      background: 'bg.png',
      color: '#C50818'
    }
  }
}, {
  id: 'eynrhw45y6uer',
  name: 'Örebro Hockey',
  shortName: 'Örebro Hockey',
  code: 'ÖHK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'rth56345w4hrhh',
    name: 'Örebro Hockey',
    shortName: 'Örebro Hockey',
    organisationNumber: '123658974856',
    branding: {
      logo: 'orebro.png',
      background: 'bg.png',
      color: '#E1032B'
    }
  }
}, {
  id: 'j67r8ie56u56j',
  name: 'IK Oskarshamn',
  shortName: 'IK Oskarshamn',
  code: 'IKO',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'e465uhtgsthws',
    name: 'IK Oskarshamn',
    shortName: 'IK Oskarshamn',
    organisationNumber: '123658974856',
    branding: {
      logo: 'oskarshamn.png',
      background: 'bg.png',
      color: '#105A96'
    }
  }
}, {
  id: 'e654uhedynggaga4hjdssdgfsg',
  name: 'Rögle BK',
  shortName: 'Rögle BK',
  code: 'RBK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'e56uedyhnf4shydygn',
    name: 'Rögle BK',
    shortName: 'Rögle BK',
    organisationNumber: '123658974856',
    branding: {
      logo: 'rogle.png',
      background: 'bg.png',
      color: '#028629'
    }
  }
}, {
  id: '54yhesrthsrthw45yhwrth',
  name: 'Skellefteå AIK',
  shortName: 'Skellefteå AIK',
  code: 'SAIK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'w45yhsrthxdfhchgm',
    name: 'Skellefteå AIK',
    shortName: 'Skellefteå AIK',
    organisationNumber: '123658974856',
    branding: {
      logo: 'skelleftea.png',
      background: 'bg.png',
      color: '#FFC100'
    }
  }
}, {
  id: 'w45yhsrthsf111',
  name: 'Timrå IK',
  shortName: 'Timrå IK',
  code: 'TIK',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: '123gsfhdghawysg',
    name: 'Timrå IK',
    shortName: 'Timrå IK',
    organisationNumber: '123658974856',
    branding: {
      logo: 'timra.png',
      background: 'bg.png',
      color: '#1C4EAB'
    }
  }
}, {
  id: 'ertyhsweg42324',
  name: 'Växsjö Lakers',
  shortName: 'Växsjö Lakers',
  code: 'VL',
  gender: 'MALE',
  favorite: false,
  category: {
    id: '507f191e810c19729de86ccc',
    name: 'Juniors'
  },
  sport: {
    id: '507f191e810c19729de86ccc',
    name: 'hockey'
  },
  association: {
    id: 'j4rutaryhfyukfyk',
    name: 'Växsjö Lakers',
    shortName: 'Växsjö Lakers',
    organisationNumber: '123658974856',
    branding: {
      logo: 'vaxjo.png',
      background: 'bg.png',
      color: '#dd601c'
    }
  }
}];
const competitionsList = [{
  id: 'w54gw45gw45gqw5g',
  name: 'Swedish Hockey League',
  shortName: 'Swedish Hockey League',
  competitionCode: 'SHL',
  gender: 'MALE',
  sportId: '507f191e810c19729de86ccc',
  categoryId: '507f191e810c19729de86ccc',
  branding: {
    logo: 'shl.png'
  }
}, {
  id: 'tyhsbser5hgs5hw',
  name: 'Svenska Damhockeyligan',
  shortName: 'Svenska Damhockeyligan',
  competitionCode: 'SDHL',
  gender: 'MALE',
  sportId: '507f191e810c19729de86ccc',
  categoryId: '507f191e810c19729de86ccc',
  branding: {
    logo: 'sdhl.png'
  }
}, {
  id: 'hs5srhtsfhgaserg',
  name: 'Hockey Allsvenskan',
  shortName: 'Hockey Allsvenskan',
  competitionCode: 'HA',
  gender: 'MALE',
  sportId: '507f191e810c19729de86ccc',
  categoryId: '507f191e810c19729de86ccc',
  branding: {
    logo: 'ha.png'
  }
}];
teams = teams.map((team, index) => _objectSpread(_objectSpread({}, team), {}, {
  id: "".concat(index),
  competitons: [{
    id: 'w54gw45gw45gqw5g'
  }]
}));
const mockDraws = [];
const {
  OPEN
} = svs.supportern.utils.tools.STATUS;
const {
  EVENT
} = svs.supportern.utils.tools.DRAW_TYPES;
for (const team of teams) {
  let i = parseInt(team.id, 10) - 1;
  if (i === -1) {
    mockDraws.push(generateDraw({
      ownerTeam: team,
      status: OPEN,
      type: EVENT
    }));
    continue;
  }
  while (i--) {
    mockDraws.push(generateDraw({
      ownerTeam: team,
      status: OPEN
    }));
    team.id % 2 && mockDraws.push(generateDraw({
      ownerTeam: team,
      status: OPEN,
      type: EVENT
    }));
  }
}
setGlobal('svs.supportern.utils.tools', {
  mockData: {
    competitions: competitionsList,
    teams,
    draws: mockDraws
  }
});
const playerDraw = {
  id: 'qwerthyfujgij',
  tenantId: 'bwloto',
  gameId: 'raffleGame',
  draw: {
    id: 'w435tysrtghsga',
    drawName: 'Some draw name',
    price: {
      currency: 'EUR',
      amount: 100
    },
    objective: 'Rasie funds for new season',
    salesStart: '2021-09-20T05:00:10.916+00:0',
    salesEnd: '2021-09-20T05:00:10.916+00:00',
    drawingTime: '2021-09-20T05:00:10.916+00:00',
    status: 'PENDING',
    ownerTeam: {},
    opponentTeam: {},
    homeGame: false,
    type: 'MATCH',
    results: {
      stats: {
        totalTickets: 10,
        totalPlayers: 10,
        poolSize: {
          currency: 'EUR',
          amount: 100
        }
      }
    }
  },
  price: {
    currency: 'EUR',
    amount: 100
  },
  prize: {
    currency: 'EUR',
    amount: 100
  },
  ticketNumbersRanges: [{
    from: 10,
    to: 19
  }]
};
const playerDraws = [];
for (let i = 0; i < 10; i++) {
  const from = _.random(10, 100);
  const to = _.random(from, from + _.random(0, 10));
  const ticketNumbersRanges = [{
    from,
    to
  }];
  if (_.random(0, 3)) {
    const nextRange = to + _.random(10, 50);
    ticketNumbersRanges.push({
      from: nextRange,
      to: nextRange + _.random(1, 6)
    });
  }
  const draw = generateDraw({
    status: tools.STATUS.OPEN
  });
  playerDraws.push(_objectSpread(_objectSpread({}, playerDraw), {}, {
    id: "player_ticket_".concat(i),
    draw,
    prize: {
      currency: 'EUR',
      amount: draw.results.stats.poolSize.amount
    },
    ticketNumbersRanges
  }));
}
setGlobal('svs.supportern.utils.tools.mockData.playerDraws', playerDraws);

 })(window);