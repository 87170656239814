(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/tickets-summary.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/tickets-summary.js');
"use strict";



const formatCurrency = svs.utils.format.Currency;
const {
  Avatar
} = svs.supportern.components;
const {
  useEffect,
  useRef
} = React;
const {
  teamAtom,
  drawAtom
} = svs.supportern.atoms;
const {
  useAtomValue
} = jotai;
const Button = svs.ui.ReactButton;
const Icon = svs.ui.ReactIcon;
const {
  DRAW_TYPES,
  STATUS
} = svs.supportern.utils.tools;
const MINIMUM_SOLD_TICKETS = 6;
const TicketsSummary = _ref => {
  var _draw$results$stats$t, _draw$results, _draw$results$stats$p, _draw$results2, _ref2, _branding, _team$association;
  let {
    branding,
    children,
    className = '',
    style = {},
    ticketNrs = [],
    isCancelled = false
  } = _ref;
  const team = useAtomValue(teamAtom);
  const draw = useAtomValue(drawAtom);
  const totalTickets = (_draw$results$stats$t = draw === null || draw === void 0 || (_draw$results = draw.results) === null || _draw$results === void 0 || (_draw$results = _draw$results.stats) === null || _draw$results === void 0 ? void 0 : _draw$results.totalRows) !== null && _draw$results$stats$t !== void 0 ? _draw$results$stats$t : 0;
  const amount = (_draw$results$stats$p = draw === null || draw === void 0 || (_draw$results2 = draw.results) === null || _draw$results2 === void 0 || (_draw$results2 = _draw$results2.stats) === null || _draw$results2 === void 0 || (_draw$results2 = _draw$results2.poolSize) === null || _draw$results2 === void 0 ? void 0 : _draw$results2.amount) !== null && _draw$results$stats$p !== void 0 ? _draw$results$stats$p : 0;
  branding = (_ref2 = (_branding = branding) !== null && _branding !== void 0 ? _branding : team === null || team === void 0 || (_team$association = team.association) === null || _team$association === void 0 ? void 0 : _team$association.branding) !== null && _ref2 !== void 0 ? _ref2 : {};
  const amountRef = useRef(null);
  const ticketsRef = useRef(null);
  const currentAmountRef = useRef({
    val: amount
  });
  const currentTotalTicketsRef = useRef({
    val: totalTickets
  });
  const ticketRef = useRef(null);
  const hasEnoughTickets = totalTickets >= MINIMUM_SOLD_TICKETS;
  const {
    status: drawStatus,
    type
  } = draw || {};
  const isActive = drawStatus === STATUS.ACTIVE;
  const totalNrOfTickets = ticketNrs.reduce((acc, _ref3) => {
    let {
      from,
      to
    } = _ref3;
    return acc + to - from + 1;
  }, 0);
  const {
    TEAM_PATH
  } = svs.supportern.routes;
  const moreThan4Tickets = totalNrOfTickets > 4;
  const moreThan10Tickets = totalNrOfTickets > 10;
  const handleBuyMoreClick = e => {
    e.preventDefault();
    svs.utils.url.navigate("".concat(svs.core.data.baseUrl).concat(TEAM_PATH.replace(':id', team.id), "#").concat(type === DRAW_TYPES.MATCH ? 'matchspel' : 'eventspel'));
  };

  useEffect(() => {
    if (branding.color) {
      ticketRef.current.style.setProperty('--ticket-branding', branding.color);
    }
    if (branding.color || branding.colorSecondary) {
      var _branding$secondColor;
      ticketRef.current.style.setProperty('--ticket-branding-secondary', (_branding$secondColor = branding.secondColor) !== null && _branding$secondColor !== void 0 ? _branding$secondColor : branding.color);
    }
    if (branding.colorBottom) {
      ticketRef.current.style.setProperty('--ticket-color-bottom', branding.colorBottom);
    }
  }, []);
  useEffect(() => {
    gsap.to(currentAmountRef.current, 1.5, {
      val: amount,
      ease: 'Power2.easeOut',
      onUpdate: () => {
        if (amountRef.current) amountRef.current.innerText = formatCurrency(Math.round(currentAmountRef.current.val));
      }
    });
    gsap.to(currentTotalTicketsRef.current, 1.5, {
      val: totalTickets,
      ease: 'Power2.easeOut',
      onUpdate: () => {
        if (ticketsRef.current) ticketsRef.current.innerText = formatCurrency(Math.round(currentTotalTicketsRef.current.val));
      }
    });
  }, [amount]);
  return React.createElement("div", {
    className: "supportern-tickets-summary supportern-ripped-top-big supportern-ripped-bottom-big f-center ".concat(className),
    ref: ticketRef,
    style: style
  }, children, !children && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "supportern-ticket-top padding-1"
  }, React.createElement(Avatar, {
    size: moreThan10Tickets ? 'smallMedium' : 'largeMedium'
  })), React.createElement("div", {
    className: "supportern-ticket-middle padding-2 bg-white ".concat(moreThan4Tickets ? 'column-2' : '')
  }, Boolean(ticketNrs) && ticketNrs.map(_ref4 => {
    let {
      from,
      to
    } = _ref4;
    return _.range(from, to + 1).map(ticketNr => React.createElement("div", {
      className: "supportern-ticket-number padding-bottom-half padding-top-half",
      key: ticketNr
    }, React.createElement("span", null, "nr ", React.createElement("span", {
      className: "supportern-ticket-number-count"
    }, "".concat(ticketNr).padStart(5, '0')))));
  }).flat().reduce((acc, val, i) => [[...acc[0], ...(moreThan4Tickets && i % 2 ? [] : [val])], [...acc[1], ...(moreThan4Tickets && i % 2 ? [val] : [])]], [[], []]).flat() 
  ), React.createElement("div", {
    className: "supportern-tickets-summary-draw padding-1 bg-white"
  }, React.createElement("div", {
    className: "".concat(isCancelled ? 'invisible' : '')
  }, React.createElement("span", {
    className: "draw-info-title"
  }, isActive ? 'Vinstsumma just nu:' : 'Vinstchans:'), React.createElement("div", {
    className: "draw-info-amount padding-top-half padding-bottom-half"
  }, React.createElement("span", {
    ref: amountRef
  }, hasEnoughTickets ? formatCurrency(amount) : 0), "\xA0", React.createElement("span", {
    className: "draw-info-currency"
  }, "kr")), isActive && React.createElement(Button, {
    block: true,
    className: "margin-bottom-1",
    inverted: true,
    onClick: handleBuyMoreClick
  }, React.createElement(Icon, {
    icon: "plus"
  }), " K\xF6p fler spel")))));
};
setGlobal('svs.supportern.components.TicketsSummary', TicketsSummary);

 })(window);